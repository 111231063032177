var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-content-table",attrs:{"dark":""}},[_c('p',{staticClass:"p-text-title"},[_vm._v("Historial de transacciones")]),_c('filter-transaction-history-component',{on:{"setDate":_vm.setDate}}),(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"iHeight":0,"sTextEmpty":_vm.sTextEmpty}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
      'items-per-page-text': 'Filas por página',
      'items-per-page-all-text': 'Todos',
    }},scopedSlots:_vm._u([{key:"item.TransactionType",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.TransactionType === 'Entrada' ? 'class-in' : 'class-out'},[_vm._v(_vm._s(item.TransactionType))])]}},{key:"item.dQuantity",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('span',[_vm._v(_vm._s(item.dQuantity > 0 ? "+" : ""))]),_vm._v(" "+_vm._s(_vm.formatMoneyGlobal(item.dQuantity))+" Kg")])]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitTransactionHistory}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-transaction-history-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitTransactionHistory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }