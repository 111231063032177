<template>
  <div dark class="global-content-table">
    <p class="p-text-title">Historial de transacciones</p>
    <filter-transaction-history-component @setDate="setDate" />
    <no-data-text-component-global :iHeight="0" :sTextEmpty="sTextEmpty" v-if="bEmptyTable" />
    <v-data-table v-else v-show="!isMobile" mobile-breakpoint="0" :headers="headers" :disable-sort="true"
      :items="aTable" :loading="bLoadingTable" loading-text="Cargando datos..." :items-per-page="iItemsPerPage"
      :hide-default-footer="true" no-data-text="No hay información disponible para mostrar." :footer-props="{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }" class="global-table">
      <template slot="progress">
        <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.TransactionType`]="{ item }">
        <span :class="item.TransactionType === 'Entrada' ? 'class-in' : 'class-out'">{{ item.TransactionType }}</span>
      </template>
      <template v-slot:[`item.dQuantity`]="{ item }">
        <span>
          <span>{{ item.dQuantity > 0 ? "+" : "" }}</span>
          {{ formatMoneyGlobal(item.dQuantity) }} Kg</span>
      </template>
      <template v-slot:footer>
        <footer-table-component-global :numPages="numPages" :totalItems="totalItems"
          @changeTable="getEmitTransactionHistory" />
      </template>
    </v-data-table>
    <!-- TableMobileTransactionHistoryComponent -->
    <table-mobile-transaction-history-component v-show="isMobile" @changeTable="getEmitTransactionHistory"
      :aTable="aTable" :headers="headers" :numPages="numPages" :totalItems="totalItems" />
  </div>

  <!-- <skeleton-transaction-history-component/> -->
</template>
<script>
export default {
  data() {
    return {
      dates: [],
      menu: false,
      // bAZ: false,
      // bZA: false,
      // bEntries: false,
      bExits: false,
      bMajorMinor: false,
      bMinorMajor: false,
      bLoadingTable: false,
      bEmptyTable: false,

      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      sTextEmpty: "",

      headers: [
        {
          text: "Empresa/Persona",
          sortable: false,
          value: "VendorType",
          class: "global-header-table global-header-enterprice-person-history",
          cellClass: "global-body-table global-body-enterprice-person-history",
        },
        {
          text: "Fecha",
          sortable: false,
          value: "tCreatedAt",
          class: "global-header-table global-header-date-history",
          cellClass: "global-body-table",
        },
        {
          text: "Transacción",
          sortable: false,
          value: "TransactionType",
          class: "global-header-table global-header-transition-history",
          cellClass: "global-body-table",
        },
        {
          text: "Cantidad procesada",
          value: "dQuantity",
          class: "global-header-table global-header-quantity-history",
          cellClass: "global-body-table",
        },
      ],
      aTable: [],
      aMonth: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      dateStart: null,
      dateEnd: null,
      isMobile: false,
    };
  },
  beforeMount() {
    this.$store.commit("setEntries", "");
    this.$store.commit("setAZ", "");
    this.setTextEmpty();
    this.getHistory();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    setDate(val) {
      this.dateStart = val.tStart;
      this.dateEnd = val.tEnd;
    },
    getEmitTransactionHistory(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getHistory();
    },
    getHistory() {
      this.bLoadingTable = true;

      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/inventory/record`,
        {
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
            sSearch: this.sSearch,
            sTransactionType: this.bEntries,
            sTransactionSort: this.bAZ,
            tStart: this.dateStart,
            tEnd: this.dateEnd,
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        }
      )
        .then((response) => {
          this.aTable = response.data.results.aRecord.map((e) => {
            return {
              ...e,
              tCreatedAt: e.tCreatedAt,
            };
          });
          this.bLoadingTable = false;
          this.bEmptyTable = this.aTable.length < 1;
          this.numPages = response.data.numPages;
          this.totalItems = response.data.total;
          this.totalView = this.aTable.length;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen transacciones registradas.</p>"
      this.sTextEmpty = sTextemptyTemp;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getAdministrators();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bEntries() {
      return this.$store.state.bEntries;
    },
    bAZ() {
      return this.$store.state.bAZ;
    },
  },
  watch: {
    dateStart() {
      this.getHistory();
    },
    dateEnd() {
      this.getHistory();
    },
    refreshTable: function () {
      this.getHistory();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getHistory();
    },
    bEntries() {
      this.iCurrentPage = 1;
      this.getHistory();
    },
    bAZ() {
      this.iCurrentPage = 1;
      this.getHistory();
    },
    iCurrentPage: function () {
      this.getHistory();
    },
  },
};
</script>
<style scoped>
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.class-in {
  color: var(--primary-color-text-in) !important;
}

.class-out {
  color: var(--primary-color-text-out) !important;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-filters {
  display: flex;
  width: 100%;
}

.content-filter-show-for {
  width: 25%;
  margin-right: 15px;
}

.content-filter-date {
  width: 25%;
}

.content-filter-date .v-text-field {
  padding-top: 0px !important;
}

.content-filter-menu-btn {
  width: 50%;
  justify-content: end;
  display: flex;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: 650px;
  min-width: 650px;
  max-width: 650px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-menu {
  width: 40px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-show-for {
    width: 100%;
    margin-right: 0px;
  }

  .content-filter-date {
    width: 100%;
  }

  .content-filter-menu-btn {
    width: 100%;
  }

  .btn-filter-menu {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-show-for {
    width: 40%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 45%;
  }

  .content-filter-menu-btn {
    width: 15%;
    justify-content: end;
    display: flex;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-filter-show-for {
    width: 25%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 30%;
  }

  .content-filter-menu-btn {
    width: 45%;
    justify-content: end;
    display: flex;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>